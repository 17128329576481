<template>
    <div>
        <vue-title title="В разработке | KEL.KZ"></vue-title>
        <Header :routes="[
      {
        id: 1,
        name: 'Dev',
        title: 'В разработке',
        hash: ''
      }
    ]"/>
        <div class="container py-4 error">
            <div class="text-center">
                <h1 class="page-title mb-4">В разработке <span class="text-muted">| Страница находится в разработке.</span><br>
                    <small>Скоро на ней появится информация. Зайдите позже.</small>
                </h1>
                <img src="@/assets/images/under-construction.svg" class="mb-4">
                <p class="mb-3">По всем вопросам вы можете связаться с нами по телефону:</p>
                <p class="font-24 text-primary">+7 (727) 312-21-10</p>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import {Header, Footer} from '../../shared/components';

    export default {
        name: 'ErrorDev',
        components: {
            Header,
            Footer
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
