var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-title',{attrs:{"title":"В разработке | KEL.KZ"}}),_c('Header',{attrs:{"routes":[
  {
    id: 1,
    name: 'Dev',
    title: 'В разработке',
    hash: ''
  }
]}}),_vm._m(0),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container py-4 error"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"page-title mb-4"},[_vm._v("В разработке "),_c('span',{staticClass:"text-muted"},[_vm._v("| Страница находится в разработке.")]),_c('br'),_c('small',[_vm._v("Скоро на ней появится информация. Зайдите позже.")])]),_c('img',{staticClass:"mb-4",attrs:{"src":require("@/assets/images/under-construction.svg")}}),_c('p',{staticClass:"mb-3"},[_vm._v("По всем вопросам вы можете связаться с нами по телефону:")]),_c('p',{staticClass:"font-24 text-primary"},[_vm._v("+7 (727) 312-21-10")])])])}]

export { render, staticRenderFns }